/* You can add global styles to this file, and also import other style files */
/*@import "bootstrap/scss/bootstrap";*/
@import "../node_modules/bootstrap/scss/bootstrap";
@import "~@ng-select/ng-select/themes/default.theme.css";

html, body {
    font-family: Roboto,sans-serif !important;
    overflow-x: hidden;
    color: #54667a;
    font-weight: 300;
    background-color: #dee2e6 !important;
    letter-spacing: -0.01em;
    min-height: 100vh !important;
    -webkit-text-size-adjust: 100% !important;
    -webkit-tap-highlight-color: transparent !important;
}

hr {
    margin: 1rem 0;
    color: inherit;
    border: 0;
    border-top: 1px solid;
    opacity: 0.25;
}

.bg-white {
    background: white !important;
}

.btn-primary-local {
    background-color: #f60 !important;
    border-radius: 0px !important;
    color: white !important;
}

.btn-primary-local-2 {
    background-color: #F8A643 !important;
    border-radius: 0px !important;
    color: white !important;
}

.btn-outline-local-2 {
    background-color: white !important;
    border-radius: 0px !important;
    border-color: #F8A643 !important;
    color: #F8A643 !important;
}

.btn-outline-local-2:hover {
    background-color: #F8A643 !important;
    border-radius: 0px !important;
    border-color: #F8A643 !important;
    color: white !important;
}

.tab-title-16 {
    width: 16% !important;
    text-align: center;
    font-size: 0.9rem;
}

.tab-title-20 {
    width: 20% !important;
    text-align: center;
    font-size: 0.9rem;
}

.tab-title-25 {
    width: 25% !important;
    text-align: center;
}

.nav-link:hover {
    color: black !important;
}

.nav-tabs .nav-item {
    margin-bottom: -1px;
}

.nav-item {
    background: 0 0 !important;
    color: #f8a643 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
}

.nav-item .active {
    border-left: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
    border-bottom: 3px solid #f8a643 !important;
    color: #f8a643 !important;
}

.nav-item:hover {
    border-left: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
}

.nav-link {
    border-left: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
}

.tab-title-33 {
    width: 33% !important;
    text-align: center;
}

.tab-title-34 {
    width: 34% !important;
    text-align: center;
}

.tab-title-50 {
    width: 50% !important;
    text-align: center;
}

h1 {
    line-height: 48px;
    font-size: 36px
}

h2 {
    line-height: 36px !important;
    font-size: 24px !important
}

h3 {
    line-height: 30px;
    font-size: 21px
}

h4 {
    line-height: 22px;
    font-size: 18px
}

h5 {
    line-height: 18px;
    font-size: 16px;
    font-weight: 400;
}

h6 {
    line-height: 16px;
    font-size: 14px;
    font-weight: 400;
}

:host {
    height: 100%
}

$amount: #008000;

.price {
    color: $amount;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0.07em;
}

a {
    text-decoration: none !important;
}

.card-body {
    border-top: 0px !important;
    background: #fff 0 0 no-repeat padding-box !important;
    opacity: 1 !important;
}

.btn-tab {
    background-color: #fff !important;
    border: 1px solid #fff !important;
    color: #1f263e !important;
    font-size: 15px !important;
    font-weight: 700 !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.text-right {
    text-align: right !important;
}

.container-fluid {
    max-width: 1366px;
}

.padding0 {
    padding: 0px !important;
}

select {
    border-color: #313b4f !important;
}

.btn-outline-warning {
    border-radius: 0 !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 2px solid #313B4F !important;
    opacity: 1 !important;
    color: #313B4F !important;
}

.btn-outline-warning:hover {
    background: #F8A643 0% 0% no-repeat padding-box !important;
    border: 2px solid #F8A643 !important;
    opacity: 1 !important;
    color: white !important;
}

.text-success {
    color: #55ce63 !important;
}

.active>.page-link, .page-link.active {
    background-color: #FF6600 !important;
    border-color: #FF6600 !important;
}

.swal2-default-outline {
    background-color: #FF6600 !important;
    border-color: #FF6600 !important;
}

.timepicker__header {
    background-color: #f8a643 !important;
}

.timepicker-button {
    color: #f8a643 !important;
}

.clock-face__number > span.active {
    background-color: #f8a643 !important;
}

ngx-material-timepicker-container {
    --clock-hand-color: #f8a643 !important;
}

.accordion-button {
    padding: 10px !important;
    font-size: 1rem;
    background-color: white !important;
    border: 0px !important;
    border-radius: 0;
    overflow-anchor: none;
}

.accordion-item{
    border: 0px !important;
}
